<template>
    <div>
        <b-col v-show="!loading" class="pl-0" v-if="(entry.phuTrach === rolePhuTrach || roleLevel == 3 && entry.archived == false)">
            <b-card style="height:300px">
                <h5 style="font-weight: bold">Cập nhật phản ánh</h5>
                <div class="mt-5 text-center">
                    <router-link :to="{ name: 'admin.sentiments.updateSentiment', params: { id: entry.id, form: entry } }">
                        <b-button variant="primary" style="width:170px">
                            Cập nhật phản ánh
                        </b-button>
                    </router-link>
                </div>
                <div class="mt-4 text-center">
                    <router-link :to="{ name: 'admin.sentiments.complete', params: { id: entry.id, form: entry } }">
                        <b-button variant="success" style="width:170px">
                            Hoàn thành xử lý
                        </b-button>
                    </router-link>
                </div>
            </b-card>
        </b-col>
        <div v-show="loading" class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
    </div>
</template>

<script>
import {
    httpClient
} from "../../../../_utils/httpClient";
import {
    httpPreview
} from "../../../../_utils/httpPreview";
import {
    Base64
} from 'js-base64'
import { checkStatusToRedirect } from "./detailSentiment"
import { checkFile } from './checkTypeFile';
export default {
    name: 'create',
    computed: {
        allowAccess() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            return userInfo.role_level > 3 && (this.form.trangThai === `Đã xử lý` || this.form.trangThai === `Không chính xác`)
        },
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Phản ánh',
                to: '/admin/sentiments',
            }, {
                text: 'Chi tiết',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            entry: {
                id: '',
                theLoai: '',
                noiDung: '',
                diaDiem: '',
                thoiGian: '',
                phuTrach: null,
                trangThai: '',
                media: [],
                logs: []
            },
            form: {
                id: '',
                noiDung: '',
            },
            loading: true,
            underUsers: [],
            sentimentStatus: [],
            getListMedia: [],
            rolePhuTrach: "",
            roleLevel: ""
        };
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        openGallery(image) {
            console.log('this.getListMedia', this.getListMedia)
            let index = this.getListMedia.findIndex(k => {
                if (k.src && image.src) {
                    let find = image.src.startsWith(`http`) ? image.src : httpPreview + image.src
                    if (k.src === find) return true
                }
                if (k.sources && k.sources[0].src && image && image.sources && image.sources[0].src) {
                    let find = image.sources[0].src.startsWith(`http`) ? image.sources[0].src : httpPreview + image.sources[0].src
                    if (k.sources[0].src === find) return true
                }
            })
            console.log(`index`, index)
            this.$refs.lightbox.showImage(index)
        },
        parseThumb(image) {
            if (image && image.sources) return `https://png.pngtree.com/png-vector/20190411/ourmid/pngtree-vector-play-icon-png-image_924817.jpg`
            if (!image.src.startsWith(`http`)) {
                return httpPreview + image.src
            }
            return image.src
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
    },
    async created() {
        this.loading = true
        let userData = localStorage.getItem('token').split('.')[1];
        let userInfo = JSON.parse(Base64.decode(userData));
        this.rolePhuTrach = userInfo.phone;
        this.roleLevel = userInfo.role_level;

        let cloneItem = await this.getById(this.$route.params.id)
        const setMedia = new Set()
        this.entry = {
            ...cloneItem
        }

        var ret = checkStatusToRedirect(
            cloneItem.trangThai,
            this.$route.name
        );
        if (ret.redirect) {
            return this.$router.replace({ name: ret.link });
        }

        this.form.id = cloneItem.id
        this.entry.trangThai = cloneItem.trangThai
        if (this.entry.logs === null) {
            this.entry.logs = []
        }
        if (cloneItem.media === undefined || cloneItem.media == null) {
            cloneItem.media = []
        } else {
            cloneItem.media.forEach(e => {
                setMedia.add(e)
            })
        }
        let media = []
        setMedia.forEach(item => {
            if (item === undefined) return
            item = item.startsWith(`http`) ? item : httpPreview + item
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item,
                    thumb: item
                })
            }
            if (this.checkTypeFile(item) == 'video') {
                media.push({
                    type: 'video',
                    sources: [{
                        src: item,
                        type: 'video/mp4'
                    }],
                    width: 800, // Required
                    height: 600, // Required
                    autoplay: true
                })
            }
        })
        this.getListMedia = media
        this.loading = false
    },
};
</script>
