import {usersService} from "../_services/users.services";

const actions = {
    fetchUsers({commit}) {
        return usersService.fetchUsers()
            .then((response) => {
                console.log(`FETCH_USERS`, response)
                commit("FETCH_USERS", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updateInfoUser({commit}, payload) {
        return usersService.updateUserInfo(payload)
            .then((response) => {
                console.log(`UPDATE_USER_INFO`, response)
                commit("UPDATE_USER_INFO", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
    deleteUser({commit}, payload) {
        return usersService.deleteUser(payload)
            .then((response) => {
                console.log(`DELETE_USER`, response)
                commit("DELETE_USER", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
}
const state = {
    users: [],
    updateUser: {},
    deleteUser: {},
    name: '',
    avatar: '',
}
const mutations = {
    setName(state, name) {
        state.name = name
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    FETCH_USERS(state, users) {
        state.users = users
    },
    UPDATE_USER_INFO(state, payload) {
        state.users = state.users.map((el => (
            el.Id === payload.id ?
                {
                    ...el, Verify: payload.verify,
                    RoleLevel: payload.roleLevel,
                    Role: [payload.role]
                }
                : el
        )))
    },
    DELETE_USER(state, payload) {
        state.deleteUser = payload
    },
}
export const user = {
    namespaced: true,
    state,
    actions,
    mutations
};