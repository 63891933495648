import {sentimentsService} from "../_services/sentiments.services";

const actions = {
    fetchSentiments({commit}) {
        return sentimentsService.fetchSentiments()
            .then((response) => {
                console.log(`FETCH_SENTIMENTS`, response)
                commit("FETCH_SENTIMENTS", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    fetchUnderUsers({commit}) {
        return sentimentsService.fetchUnderUsers()
            .then((response) => {
                console.log(`FETCH_UNDER_USER`, response)
                commit("FETCH_UNDER_USER", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    postTaskSentiment({commit}, payload) {
        return sentimentsService.postTaskSentiment(payload)
            .then((response) => {
                console.log(`POST_TASK_SENTIMENT`, response)
                commit("POST_TASK_SENTIMENT", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
}
const state = {
    sentiments: [],
    underUsers: [],
    post: [],
}
const mutations = {
    FETCH_SENTIMENTS(state, sentiments) {
        state.sentiments = sentiments
    },
    FETCH_UNDER_USER(state, underUsers) {
        state.underUsers = underUsers
    },
    POST_TASK_SENTIMENT(state, payload) {
        let trangThai = payload.trangThai ? `Hoàn thành` : `Đang xử lý`
        state.sentiments = state.sentiments.map((el => (
            el.id === payload.id ? {...el, trangThai} : el
        )))
    },
}
export const sentiment = {
    namespaced: true,
    state,
    actions,
    mutations
};