import {httpClient} from "../_utils/httpClient";

export const decisionsService = {
    fetchDecisions, addDecision, deleteDecision
};

function fetchDecisions() {
    return httpClient.get(`decision`)
        .then(result => {
            if (result.data.code === 200) {
                return result.data.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}

function addDecision(payload) {
    return httpClient.post(`decision`, payload)
        .then(result => {
            if (result.status === 200) {
                return result.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}

function deleteDecision(payload) {
    return httpClient.post(`decision/delete`, payload)
        .then(result => {
            if (result.status === 200) {
                return result.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}