import {httpClient} from "../_utils/httpClient";

export const docsService = {
    fetchDocs, addDoc, deleteDoc
};

function fetchDocs() {
    return httpClient.get(`doc`)
        .then(result => {
            if (result.data.code === 0) {
                return result.data.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}

function addDoc(payload) {
    return httpClient.post(`doc`, payload)
        .then(result => {
            if (result.status === 200) {
                return result.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}

function deleteDoc(payload) {
    return httpClient.post(`doc/delete`, payload)
        .then(result => {
            if (result.status === 200) {
                return result.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}