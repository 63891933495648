import Vue from 'vue'
import App from './App.vue'
import {router} from './router';
import {store} from "./_store";
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { Base64 } from 'js-base64'
import {filter} from './filter'
import firebase from "firebase";
import { configOptions } from './firebase-config'
import ResultInfo from '../src/pages/app/sentiments/partials/resultInfo.vue'
import StatusInfo from '../src/pages/app/sentiments/partials/statusInfo.vue'
import EditInfo from '../src/pages/app/sentiments/partials/editInfo.vue'
import CompleteInfo from '../src/pages/app/sentiments/partials/completeInfo.vue'
import UpdateDateInfo from '../src/pages/app/sentiments/partials/updateDateInfo.vue'
import UpdateSentimentInfo from '../src/pages/app/sentiments/partials/updateSentimentInfo.vue'

firebase.initializeApp(configOptions);
Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.config.performance = true

Vue.prototype.$confirmationResult = window.confirmationResult;

Vue.component('result-info', ResultInfo)
Vue.component('update-status-info', StatusInfo)
Vue.component('edit-info', EditInfo)
Vue.component('complete-info', CompleteInfo)
Vue.component('update-date-info', UpdateDateInfo)
Vue.component('update-sentiment-info', UpdateSentimentInfo)
Vue.mixin({
    methods: {
        b64DecodeUnicode: function (str) {
            // Going backwards: from bytestream, to percent-encoding, to original string.
            return decodeURIComponent(Base64.decode(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }
    },
})

new Vue({
    el: '#app',
    router,
    store,
    filter,
    template: '<App/>',
    render: h => h(App),
    components: {
        App
    }
})