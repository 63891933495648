<template>
    <div>
        <b-col v-show="!loading" class="pl-0" v-if="entry.archived == false && (entry.phuTrach === rolePhuTrach || roleLevel == 3)">
            <b-card style="height:300px">
                <h5 style="font-weight: bold">Cập nhật phản ánh</h5>
                <div class="mt-5 text-center">
                    <router-link :to="{ name: 'admin.sentiments.updateDate', params: { id: entry.id, form: entry } }">
                        <b-button variant="primary" style="width: 180px">
                            Tiếp nhận phản ánh
                        </b-button>
                    </router-link>
                </div>
                <div class="mt-4 text-center">
                    <router-link :to="{ name: 'admin.sentiments.updateSentiment', params: { id: entry.id, form: entry } }">
                        <b-button variant="success" style="width: 180px">
                            Cập nhật phản ánh
                        </b-button>
                    </router-link>
                </div>
            </b-card>
        </b-col>
        <div v-show="loading" class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
    </div>
</template>

<script>
import {
    httpClient
} from "../../../../_utils/httpClient";
import {
    httpPreview
} from "../../../../_utils/httpPreview";
import _ from "lodash";
import {
    Base64
} from 'js-base64'
import { checkStatusToRedirect } from "./detailSentiment"
import { checkFile } from './checkTypeFile';

export default {
    name: 'create',
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Phản ánh',
                to: '/admin/sentiments',
            }, {
                text: 'Chi tiết',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            entry: {
                theLoai: '',
                noiDung: '',
                diaDiem: '',
                thoiGian: '',
                phuTrach: null,
                trangThai: '',
                media: [],
                logs: []
            },
            form: {
                id: '',
                noiDung: '',
                trangThai: null,
            },
            loading: true,
            sentimentStatus: [],
            getListMedia: [],
            rolePhuTrach: "",
            roleLevel: ""
        };
    },
    computed: {
        allowAccess() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            return userInfo.role_level > 3 && (this.form.trangThai === `Đã xử lý` || this.form.trangThai === `Không chính xác`)
        },
    },
    methods: {
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
        checkTypeFile(src) {
            return checkFile(src);
        }
    },
    async created() {
        this.loading = true
        let userData = localStorage.getItem('token').split('.')[1];
        let userInfo = JSON.parse(Base64.decode(userData));
        this.rolePhuTrach = userInfo.phone;
        this.roleLevel = userInfo.role_level;
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id)
        }

        var ret = checkStatusToRedirect(
            cloneItem.trangThai,
            this.$route.name
        );
        if (ret.redirect) {
            return this.$router.replace({ name: ret.link });
        }

        const setMedia = new Set()
        this.entry = {
            ...cloneItem
        }
        if (this.entry.logs === null) {
            this.entry.logs = []
        }
        this.form.id = cloneItem.id
        this.form.phuTrach = cloneItem.phuTrach
        this.form.trangThai = cloneItem.trangThai === `Đang chờ xử lý` ? `Đã tiếp nhận` : cloneItem.trangThai
        if (cloneItem.media === undefined || cloneItem.media == null) {
            cloneItem.media = []
        } else {
            cloneItem.media.forEach(e => {
                setMedia.add(e)
            })
        }
        let media = []
        setMedia.forEach(item => {
            if (item === undefined) return
            item = item.startsWith(`http`) ? item : httpPreview + item
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item,
                    thumb: item
                })
            }
            if (this.checkTypeFile(item) == 'video') {
                media.push({
                    type: 'video',
                    sources: [{
                        src: item,
                        type: 'video/mp4'
                    }],
                    width: 800, // Required
                    height: 600, // Required
                    autoplay: true
                })
            }
        })
        this.getListMedia = media
        this.loading = false
    },
};
</script>
