import Vue from 'vue';
import Vuex from 'vuex';

import {account} from './account.module';
import {sidebar} from './sidebar'
import {doc} from './doc.module'
import {decision} from './decision.module'
import {user} from './user.module'
import {sentiment} from './sentiment.module'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        sentiment,
        account,
        user,
        doc,
        decision,
        sidebar
    }
});