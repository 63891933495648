export const checkStatusToRedirect = (status, name) => {
    if (status == "Chờ xử lý" && name != 'admin.sentiments.edit') {
        return {redirect : true, link : 'admin.sentiments.edit' }
    }
    else if (status == "Đang xử lý" && name != 'admin.sentiments.updateStatus') {
        return {redirect : true, link : 'admin.sentiments.updateStatus' }
    }
    else if ((status == "Đã xử lý" || status == "Không xử lý") && name != 'admin.sentiments.result') {
        return {redirect : true, link : 'admin.sentiments.result' }
    }
    return { redirect : false }
}

