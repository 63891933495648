import {httpClient} from "../_utils/httpClient";

export const sentimentsService = {
    fetchSentiments, fetchUnderUsers, postTaskSentiment
};

function fetchSentiments() {
    return httpClient.get(`sentiment`)
        .then(result => {
            if (result.status === 200) {
                return result.data.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}

function fetchUnderUsers() {
    return httpClient.get(`user/under`)
        .then(result => {
            if (result.data.code === 0) {
                return result.data.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}

function postTaskSentiment(payload) {
    return httpClient.post(`sentiment/assign`, payload)
        .then(result => {
            if (result.status === 200) {
                return result.data
            } else {
                return result
            }
        })
        .catch(error => {
            console.log(`e`, error)
        });
}