var protocol = window.location.protocol;
var currentHost = "";

if(process.env.NODE_ENV == "development") {
    currentHost = process.env.VUE_APP_HOST_DEVELOPMENT
} else {
    currentHost = `${protocol}//${window.location.hostname}`
}
export const httpPreview = currentHost + "/";



