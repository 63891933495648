const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false
}
const actions = {
    toggleSidebarDesktop({commit}) {
        commit('toggleSidebarDesktop');
    },
    toggleSidebarMobile({commit}) {
        commit('toggleSidebarMobile');
    },
    set({commit}, state) {
        commit('set', state);
    }
}
const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    }
}
export const sidebar = {
    namespaced: true,
    state,
    mutations,
    actions
};
