import Router from 'vue-router';
import Vue from 'vue'


Vue.use(Router)
const Login = () => import('./pages/login/index')
export const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            children: [
                {
                    name: 'auth.login',
                    path: '/login',
                    component: () => import('./pages/login/Login')
                }
            ]
        },
        {
            path: '/auth',
            name: 'auth',
            component: () => import('./pages/forgotPass/index'),
            redirect: '/auth/phone',
            children: [
                {
                    path: '/auth/phone',
                    name: 'auth.phone',
                    component: () => import('./pages/forgotPass/authPhone')
                },
                {
                    path : '/auth/email',
                    name : 'auth.email',
                    component : () => import('./pages/forgotPass/authEmail'),
                },
            ]
        },
        {
            path: '/signup',
            name: 'SignUp',
            component: () => import('./pages/signup/SignUp')
        },
        {
            path: '/admin',
            name: 'app',
            component: () => import('./pages/app'),
            redirect: '/admin/sentiments',
            children: [
                {
                    name: 'admin',
                    path: '/',
                    component: () => import('./pages/app/home/index'),
                    redirect: {
                        name: 'admin.home',
                    },
                },
                {
                    name: 'admin.profile',
                    path: '/admin/profile',
                    component: () => import('./pages/app/profile/update'),
                },
                {
                    name: 'admin.change-password',
                    path: '/admin/change-password',
                    component: () => import('./pages/app/profile/change-password'),
                },
                {
                    name: 'admin.home',
                    path: '/admin/home',
                    component: () => import('./pages/app/home/index'),
                },
                {
                    name: 'admin.surveys',
                    path: '/admin/surveys',
                    component: () => import('./pages/app/surveys'),
                    redirect: {
                        name: 'admin.surveys.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.surveys.list',
                            component: () => import('./pages/app/surveys/list'),
                        }, {
                            path: 'create',
                            name: 'admin.surveys.create',
                            component: () => import('./pages/app/surveys/create'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.surveys.edit',
                            component: () => import('./pages/app/surveys/edit'),
                        },
                        {
                            path: 'detail/:id',
                            name: 'admin.surveys.detail',
                            component: () => import('./pages/app/surveys/detail'),
                        },
                        {
                            path: 'statistic/:id',
                            name: 'admin.surveys.statistic',
                            component: () => import('./pages/app/surveys/statistic'),
                        },
                    ],
                },
                {
                    name: 'admin.informations',
                    path: '/admin/informations',
                    component: () => import('./pages/app/informations'),
                    redirect: {
                        name: 'admin.informations.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.informations.list',
                            component: () => import('./pages/app/informations/list'),
                        }, {
                            path: 'create',
                            name: 'admin.informations.create',
                            component: () => import('./pages/app/informations/create'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.informations.edit',
                            component: () => import('./pages/app/informations/edit'),
                        },
                        {
                            path: 'view/:id',
                            name: 'admin.informations.view',
                            component: () => import('./pages/app/informations/view'),
                        },
                    ],
                },
                {
                    name: 'admin.sentiments',
                    path: '/admin/sentiments',
                    component: () => import('./pages/app/sentiments'),
                    redirect: {
                        name: 'admin.sentiments.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.sentiments.list',
                            component: () => import('./pages/app/sentiments/list'),
                        },
                        {
                            path: 'edit/:id',
                            name: 'admin.sentiments.edit',
                            component: () => import('./pages/app/sentiments/update'),
                        },
                        {
                            path: 'update-sentiment/:id',
                            name: 'admin.sentiments.updateSentiment',
                            component: () => import('./pages/app/sentiments/update'),
                        },
                        {
                            path: 'update-status/:id',
                            name: 'admin.sentiments.updateStatus',
                            component: () => import('./pages/app/sentiments/update'),
                        },
                        {
                            path: 'update-date/:id',
                            name: 'admin.sentiments.updateDate',
                            component: () => import('./pages/app/sentiments/update'),
                        },
                        {
                            path: 'complete/:id',
                            name: 'admin.sentiments.complete',
                            component: () => import('./pages/app/sentiments/update'),
                        },
                        {
                            path: 'result/:id',
                            name: 'admin.sentiments.result',
                            component: () => import('./pages/app/sentiments/update'),
                            meta: {
                                componentName: ''
                            }
                        },
                        {
                            path: 'archived',
                            name: 'admin.sentiments.archived',
                            component: () => import('./pages/app/sentiments/archived'),
                        },
                    ],
                },
                {
                    name: 'admin.users',
                    path: '/admin/users',
                    component: () => import('./pages/app/users'),
                    redirect: {
                        name: 'admin.users.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.users.list',
                            component: () => import('./pages/app/users/list'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.users.edit',
                            component: () => import('./pages/app/users/edit'),
                        }, {
                            path: 'create',
                            name: 'admin.users.create',
                            component: () => import('./pages/app/users/create'),
                        }, {
                            path: 'import-excel',
                            name: 'admin.users.import-excel',
                            component: () => import('./pages/app/users/importExcel'),
                        }, {
                            path: 'resident',
                            name: 'admin.users.resident.list',
                            component: () => import('./pages/app/users/resident'),
                        },
                        {
                            path: 'resident/edit/:id',
                            name: 'admin.users.resident.edit',
                            component: () => import('./pages/app/users/edit'),
                        },
                    ],
                },
                {
                    name: 'admin.config.category',
                    path: '/admin/config-category',
                    component: () => import('./pages/app/config-category'),
                    redirect: {
                        name: 'admin.config.category.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.config.category.list',
                            component: () => import('./pages/app/config-category/list'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.config.category.edit',
                            component: () => import('./pages/app/config-category/edit'),
                        },
                        {
                            path: 'create',
                            name: 'admin.config.category.create',
                            component: () => import('./pages/app/config-category/create'),
                        },
                    ],
                },
                {
                    name: 'admin.config.plks',
                    path: '/admin/config-plks',
                    component: () => import('./pages/app/config-plks'),
                    redirect: {
                        name: 'admin.config.plks.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.config.plks.list',
                            component: () => import('./pages/app/config-plks/list'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.config.plks.edit',
                            component: () => import('./pages/app/config-plks/edit'),
                        },
                        {
                            path: 'create',
                            name: 'admin.config.plks.create',
                            component: () => import('./pages/app/config-plks/create'),
                        },
                    ],
                },
                {
                    name: 'admin.config.lvtt',
                    path: '/admin/config-lvtt',
                    component: () => import('./pages/app/config-lvtt'),
                    redirect: {
                        name: 'admin.config.lvtt.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.config.lvtt.list',
                            component: () => import('./pages/app/config-lvtt/list'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.config.lvtt.edit',
                            component: () => import('./pages/app/config-lvtt/edit'),
                        },
                        {
                            path: 'create',
                            name: 'admin.config.lvtt.create',
                            component: () => import('./pages/app/config-lvtt/create'),
                        },
                    ],
                },
                {
                    name: 'admin.config.assignees',
                    path: '/admin/config-assignees',
                    component: () => import('./pages/app/config-assignees'),
                    redirect: {
                        name: 'admin.config.assignees.list',
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'admin.config.assignees.list',
                            component: () => import('./pages/app/config-assignees/list'),
                        }, {
                            path: 'edit/:id',
                            name: 'admin.config.assignees.edit',
                            component: () => import('./pages/app/config-assignees/edit'),
                        },
                        {
                            path: 'create',
                            name: 'admin.config.assignees.create',
                            component: () => import('./pages/app/config-assignees/create'),
                        },
                    ],
                },
                {
                    name: 'admin.config.upload.excel',
                    path: '/admin/config-upload-excel',
                    component: () => import('./pages/app/config-upload-excel'),
                    redirect: {
                        name: 'admin.config.upload.excel',
                    },
                    children: [
                        {
                            path: 'create',
                            name: 'admin.config.upload.excel.create',
                            component: () => import('./pages/app/config-upload-excel/create'),
                        }
                    ],
                }
            ]
        },
        {
            path: "*",
            name: 'admin.home',
            component: () => import('./pages/app/home/index'),
            redirect: {
                name: 'admin.home',
            },
        },
    ]
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/signup', '/auth/phone','/auth/email'];
    let authRequired = false
    if(!(publicPages.includes(to.path)) ) authRequired = true
    const loggedIn = localStorage.getItem('token');
    if (loggedIn && !authRequired) {
        return next('/admin')
    }
    if (authRequired && !loggedIn) {
        return next('/login');
    }
    next();
})
