<template>
    <div>
        <b-col v-if="entry.phuTrach === rolePhuTrach || roleLevel == 3">
            <b-card>
                <h5 style="font-weight: bold">Hoàn thành xử lý</h5>
                <div class="mt-5">
                    <b-form-group>
                        <label>Kết quả <span class="red">*</span></label>
                        <b-form-select v-model="selected">
                            <b-form-select-option :value=true>Đã xử lý</b-form-select-option>
                            <b-form-select-option :value=false>Không xử lý</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <b-form-group>
                    <label>Nội dung trả lời <span class="red">*</span></label>
                    <b-textarea v-model="form.noiDungTraLoi" />
                </b-form-group>
                <b-form-group>
                    <sentiment-files :label="'Tải lên tài liệu/ảnh'" :value="form.mediaResult"
                        @input="value => form.mediaResult = value" />
                </b-form-group>
                <b-form-group class="mt-3">
                    <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                        {{ addErrorMessage }}
                    </div>
                    <b-button variant="success" @click="complete" class="mr-2">
                        Hoàn thành
                    </b-button>
                    <router-link :to="{ name: 'admin.sentiments.updateStatus' }">
                        <b-button variant="danger">
                            Hủy bỏ
                        </b-button>
                    </router-link>
                </b-form-group>
            </b-card>
        </b-col>
        <LightBox ref="lightbox" :showCaption="false" :showLightBox="false" :media="getListMedia" />
    </div>
</template>

<script>
import SentimentFiles from '../partials/sentiment-files';
import {
    httpClient
} from "../../../../_utils/httpClient";
import {
    httpPreview
} from "../../../../_utils/httpPreview";
import _ from "lodash";
import LightBox from 'vue-it-bigger'
import {
    Base64
} from 'js-base64'
import { checkFile } from './checkTypeFile';
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        LightBox,
        SentimentFiles
    },
    computed: {
        allowAccess() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            return userInfo.role_level > 3 && (this.form.trangThai === `Đã xử lý` || this.form.trangThai === `Không chính xác`)
        },
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Phản ánh',
                to: '/admin/sentiments',
            }, {
                text: 'Chi tiết',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                id: "",
                noiDungTraLoi: "",
                mediaResult: [],
            },
            entry: {
                phuTrach: "",
            },
            selected: true,
            loading: false,
            getListMedia: [],
            rolePhuTrach: "",
            roleLevel: ""
        };
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        validator() {
            if (this.form.noiDungTraLoi === "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Nội dung không được trống.`
                return false
            }
            return true
        },
        async complete() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            let payload = {
                ...this.form,
                ketQuaPhanAnh: this.selected
            }
            await Swal.queue([{
                title: 'Hoàn thành phản ánh này?',
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`sentiment/complete`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Hoàn thành phản ánh thành công',
                        });
                        await this.$router.push({
                            name: 'admin.sentiments.result',
                            params: {
                                id: this.entry.id,
                                form: this.entry
                            }
                        });
                    } else {
                        Swal.insertQueueStep({
                            title: 'Hoàn thành phản ánh không thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data.message
                    }
                    return true;
                },
            },]);
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
    },
    async created() {
        let userData = localStorage.getItem('token').split('.')[1];
        let userInfo = JSON.parse(Base64.decode(userData));
        this.rolePhuTrach = userInfo.phone;
        this.roleLevel = userInfo.role_level;
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id)
        }
        const setMedia = new Set()
        this.entry = {
            ...cloneItem
        }
        if (this.entry.logs === null) {
            this.entry.logs = []
        }
        this.form.id = cloneItem.id
        this.entry.phuTrach = cloneItem.phuTrach
        if (cloneItem.media === undefined || cloneItem.media == null) {
            cloneItem.media = []
        } else {
            cloneItem.media.forEach(e => {
                setMedia.add(e)
            })
        }
        let media = []
        setMedia.forEach(item => {
            if (item === undefined) return
            item = item.startsWith(`http`) ? item : httpPreview + item
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item,
                    thumb: item
                })
            }
            if (this.checkTypeFile(item) == 'video') {
                media.push({
                    type: 'video',
                    sources: [{
                        src: item,
                        type: 'video/mp4'
                    }],
                    width: 800, // Required
                    height: 600, // Required
                    autoplay: true
                })
            }
        })
        this.getListMedia = media
    },
};
</script>
