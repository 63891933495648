<template lang="vi">
<div>
    <b-col class="pl-0">
        <b-card>
            <h5 style="font-weight: bold">Cập nhật phản ánh</h5>
            <div class="mt-5">
                <b-form-group>
                    <label class="mb-1 font-weight-medium">Danh mục <span class="red">*</span></label>
                    <multiselect v-model="entry.theLoai" :options="categories" placeholder="Danh mục" :clear-on-select="false" :close-on-select="true" :hide-selected="false" :show-labels="false" :internal-search="false" :multiple="false">
                        <span slot="noResult">Không tìm thấy đơn vị này</span>
                        <span slot="noOptions">Danh sách trống</span>
                        <template slot="option" slot-scope="{ option }">
                            <div class="option__desc">
                                <span class="option__small">
                                    {{ option }}
                                </span>
                            </div>
                        </template>
                    </multiselect>
                </b-form-group>
                <b-form-group>
                    <label class="mb-1 font-weight-medium">Người phụ trách <span class="red">*</span></label>
                    <b-form-select v-model="entry.phuTrach">
                        <b-form-select-option :value="null" disabled>Phụ trách</b-form-select-option>
                        <option v-for="(user) in underUsers" :key="user.id" :value="user.id">
                            {{ user.name }} - ({{ user.position }})
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="mt-4">
                <label>Hạn xử lý</label>
                <date-picker v-model="entry.thoiGianKetThuc" value-type="timestamp" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY" placeholder="Chọn hạn xử lý" :disabled-date="notBeforeToday"></date-picker>
            </div>
            <b-form-group class="mt-4">
                <label>Nội dung cập nhật<span class="red">*</span></label>
                <b-textarea v-model="form.noiDungUpdate" />
            </b-form-group>
            <b-form-group class="mt-3">
                <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                    {{addErrorMessage}}
                </div>
                <b-button variant="success" class="mr-2" @click.prevent="create">
                    Lưu lại
                </b-button>
                <router-link v-if="entry.trangThai == 'Chờ xử lý'" :to="{name: 'admin.sentiments.edit', params: {id:entry.id , form: entry}}">
                    <b-button variant="danger">
                        Hủy bỏ
                    </b-button>
                </router-link>
                <router-link v-else :to="{name: 'admin.sentiments.updateStatus', params: {id:entry.id , form: entry}}">
                    <b-button variant="danger">
                        Hủy bỏ
                    </b-button>
                </router-link>
            </b-form-group>
        </b-card>
    </b-col>
</div>
</template>

<script>
import PageHeader from '../../../../components/page-header';
import Detail from "../partials/detail.vue"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/vi';
import {
    httpClient
} from "../../../../_utils/httpClient";
import {
    httpPreview
} from "../../../../_utils/httpPreview";
import _ from "lodash";
import Multiselect from 'vue-multiselect';
import SentimentFiles from "../partials/sentiment-files.vue";
import LightBox from 'vue-it-bigger'
import {
    Base64
} from 'js-base64'
import { checkFile } from './checkTypeFile';
export default {
    name: 'create',
    components: {
        SentimentFiles,
        PageHeader,
        LightBox,
        Detail,
        Multiselect,
        DatePicker
    },
    computed: {
        allowAccess() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            return userInfo.role_level > 3 && (this.form.trangThai === `Đã xử lý` || this.form.trangThai === `Không chính xác`)
        },
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Phản ánh',
                to: '/admin/sentiments',
            }, {
                text: 'Chi tiết',
                to: '#',
                active: true,
            }, ],
            showAddErrorMessage: false,
            addErrorMessage: '',
            entry: {
                id: "",
                theLoai: '',
                noiDung: '',
                diaDiem: '',
                thoiGian: '',
                download: "",
                phuTrach: "",
                trangThai: "",
                media: [],
                logs: [],
                thoiGianKetThuc: null,
            },
            form: {
                noiDungUpdate: '',
            },
            loading: false,
            underUsers: [],
            categories: [],
            sentimentStatus: [],
            getListMedia: [],
            rolePhuTrach: "",
            roleAdmin: "",
        };
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        notBeforeToday(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        validator() {
            if (this.entry.theLoai === "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Danh mục không được trống.`
                return false
            }
            if (this.entry.phuTrach === "" || this.entry.phuTrach == null) {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Phụ trách không được trống.`
                return false
            }
            if (this.form.noiDungUpdate.trim() == "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Nội dung không được trống.`
                return false
            }
            return true
        },
        async getUnderUser() {
            let response = await httpClient.get(`user/under`)
            let responseData = response.data;
            if (responseData.code === 0) {
                this.underUsers = responseData.data;
            }
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'category'
                }
            })
            let responseData = response.data;
            if (responseData.code === 0) {
                this.categories = responseData.data.map(el => {
                    return el.name
                })
            }
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            let payload = {
                ...this.form,
                theLoai: this.entry.theLoai,
                phuTrach: this.entry.phuTrach,
                thoiGianKetThuc: this.entry.thoiGianKetThuc,
                trangThai: "Chờ xử lý"
            }
            let response = await httpClient.post(`sentiment/update`, payload)
            if (response.data.code === 0) {
                if (this.entry.trangThai !== "Chờ xử lý") {
                    console.log("noback");
                    await this.$router.push({
                        name: 'admin.sentiments.updateStatus',
                        params: {
                            id: this.entry.id,
                            form: this.entry
                        }
                    })
                } else {
                    console.log("back");
                    this.$router.back();
                }
            } else {
                this.showAddErrorMessage = true
                this.addErrorMessage = response.data
            }
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
    },
    async created() {
        let userData = localStorage.getItem('token').split('.')[1];
        let userInfo = JSON.parse(Base64.decode(userData));
        this.rolePhuTrach = userInfo.phone;
        this.roleAdmin = userInfo.role;
        await this.getCategories();
        await this.getUnderUser();
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id)
        }
        const setMedia = new Set()
        this.entry = {
            ...cloneItem
        }
        this.form.id = cloneItem.id;
        this.entry.trangThai = cloneItem.trangThai
        this.entry.phuTrach = cloneItem.phuTrach
        if (this.entry.thoiGianKetThuc == 0 || this.entry.thoiGianKetThuc == null || this.entry.thoiGianKetThuc == "") {
            this.entry.thoiGianKetThuc = new Date().setHours(0, 0, 0, 0);
        }
        if (this.entry.logs === null) {
            this.entry.logs = []
        }
        this.entry.trangThai = cloneItem.trangThai
        if (cloneItem.media === undefined || cloneItem.media == null) {
            cloneItem.media = []
        } else {
            cloneItem.media.forEach(e => {
                setMedia.add(e)
            })
        }
        let media = []
        setMedia.forEach(item => {
            if (item === undefined) return
            item = item.startsWith(`http`) ? item : httpPreview + item
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item,
                    thumb: item
                })
            }
            if (this.checkTypeFile(item) == 'video') {
                media.push({
                    type: 'video',
                    sources: [{
                        src: item,
                        type: 'video/mp4'
                    }],
                    width: 800, // Required
                    height: 600, // Required
                    autoplay: true
                })
            }
        })
        this.getListMedia = media
    },
};
</script>