export const configOptions = {
    apiKey: "AIzaSyA1YdyAJ-96qScywZdOswFjWO6YPSOKq_M",
    authDomain: "social-listening-c333d.firebaseapp.com",
    projectId: "social-listening-c333d",
    storageBucket: "social-listening-c333d.appspot.com",
    messagingSenderId: "690933932731",
    appId: "1:690933932731:web:e4f5d3b859226c5b87ff9e",
    measurementId: "G-0489CLQSKJ"
}




