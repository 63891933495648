export const checkFile = (src) => {
    if(src.endsWith(`.jpg`) || src.endsWith(`.jpeg`) || src.endsWith(`.png`))
    {
      return 'image';
    }
    else if((src.endsWith(`mp4`) || src.endsWith(`mov`) ||  src.endsWith('MOV')))
    {
       return 'video';
    }
    else {
        return 'file';
    }
}

