<template>
    <div>
        <div v-show="!loading">
            <b-card>
                <h5 style="font-weight: bold">{{ entry.ketQuaPhanAnh ? "Phản ánh này đã xử lý" : "Phản ánh này không xử lý" }}
                </h5>
                <div class="mt-4">
                    <b-form-group>
                        <span style="font-weight:bold">Người xử lý: </span>
                        <span>{{ entry.tenPhuTrach }}</span>
                    </b-form-group>
                    <b-form-group>
                        <span style="font-weight:bold">Kết quả: </span>
                        <span v-if="entry.ketQuaPhanAnh == true">Đã xử lý</span>
                        <span v-else>Không xử lý</span>
                    </b-form-group>
                    <b-form-group>
                        <span style="font-weight:bold">Nội dung trả lời: </span>
                        <span>{{ entry.noidungTraLoi }}</span>
                    </b-form-group>
                    <b-form-group>
                        <span style="font-weight:bold">Ngày xử lý: </span>
                        <span>{{ entry.thoiGianTraLoi | formatDate }}</span>
                    </b-form-group>
                    <div v-for="media in entry.mediaResult" :key="media">
                        <div class="mt-3" v-if="media && (checkTypeFile(media) == 'image')">
                            <label style="font-weight:bold">Hình ảnh:</label>
                            <div>
                                <img :src="parseThumb(media)" style="height: 250px; width:250px; cursor: pointer" />
                            </div>
                        </div>
                        <div class="mt-3" v-else-if="media && checkTypeFile(media) == 'video'">
                            <span style="font-weight:bold">Video: </span>
                            <b-link :href="addPrefixImage(media)">{{ media.split("/")[4] }}</b-link>
                        </div>
                        <div class="mt-3" v-else>
                            <span style="font-weight:bold">Tài liệu: </span>
                            <b-link :href="addPrefixImage(media)">{{ media.split("/")[4] }}</b-link>
                        </div>
                    </div>
                    <b-form-group class="mt-2">
                        <span style="font-weight:bold">Đánh giá : </span>
                        <b-form-rating v-model="entry.danhGia" readonly variant="warning" class="mb-2 mt-2">
                        </b-form-rating>
                    </b-form-group>
                    <b-form-group v-if="entry.noidungDanhGia !== ''">
                        <span style="font-weight:bold">Nội dung đánh giá: </span>
                        <span>{{ entry.noidungDanhGia }}</span>
                    </b-form-group>
                </div>
            </b-card>
            <LightBox ref="lightbox" :showCaption="false" :showLightBox="false" :media="entry.mediaResult" />
        </div>
        <div v-show="loading" class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
    </div>
</template>

<script>
import { httpClient } from "../../../../_utils/httpClient";
import { httpPreview } from "../../../../_utils/httpPreview";
import {
    checkStatusToRedirect
} from "./detailSentiment"
import { checkFile } from "./checkTypeFile";
import LightBox from 'vue-it-bigger'

export default {
    name: 'create',
    components: {
        LightBox
    },
    data: function () {
        return {
            showAddErrorMessage: false,
            addErrorMessage: '',
            entry: {
                id: "",
                theLoai: '',
                noiDung: '',
                diaDiem: '',
                thoiGian: '',
                phuTrach: null,
                ketQuaPhanAnh: "",
                mediaResult: [],
                trangThai: '',
                media: [],
                noiDungTraLoi: "",
                logs: [],
                noidungDanhGia: ''
            },
            form: {
                id: ""
            },
            surveys: "",
            loading: true,
            sentimentStatus: [],
            getListMedia: [],
        };
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
        addPrefixImage(src) {
            if (src && src.startsWith(`http`)) return src
            return httpPreview + src
        },
        parseThumb(image) {
            if (image && image.sources) return `https://png.pngtree.com/png-vector/20190411/ourmid/pngtree-vector-play-icon-png-image_924817.jpg`
            else {
                return httpPreview + image
            }
        },
    },
    async created() {
        this.loading = true
        let cloneItem = await this.getById(this.$route.params.id)
        var ret = checkStatusToRedirect(
            cloneItem.trangThai,
            this.$route.name
        );
        if (ret.redirect) {
            return this.$router.replace({
                name: ret.link
            });
        }
        this.entry = {
            ...cloneItem
        }
        const setMedia = new Set()
        if (this.entry.logs === null) {
            this.entry.logs = []
        }
        this.form.id = cloneItem.id;
        this.entry.mediaResult = cloneItem.mediaResult;
        this.entry.noidungDanhGia = cloneItem.noidungDanhGia;
        if (cloneItem.media === undefined || cloneItem.media == null) {
            cloneItem.media = []
        } else {
            cloneItem.media.forEach(e => {
                setMedia.add(e)
            })
        }
        let media = []
        setMedia.forEach(item => {
            if (item === undefined) return
            item = item.startsWith(`http`) ? item : httpPreview + item
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item,
                    thumb: item
                })
            }
            if (this.checkTypeFile(item) == 'video') {
                media.push({
                    type: 'video',
                    sources: [{
                        src: item,
                        type: 'video/mp4'
                    }],
                    width: 800, // Required
                    height: 600, // Required
                    autoplay: true
                })
            }
        })
        this.getListMedia = media
        this.loading = false
    },
};
</script>
