import {decisionsService} from "../_services/decisions.services";

const actions = {
    fetchDecisions({commit}) {
        return decisionsService.fetchDecisions()
            .then((response) => {
                console.log(`FETCH_DECISIONS`, response)
                commit("FETCH_DECISIONS", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    addDecision({commit}, payload) {
        return decisionsService.addDecision(payload)
            .then((response) => {
                console.log(`ADD_DECISION`, response)
                commit("ADD_DECISION", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
    deleteDecision({commit}, payload) {
        return decisionsService.deleteDecision(payload)
            .then((response) => {
                console.log(`DELETE_DECISION`, response)
                commit("DELETE_DECISION", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
}
const state = {
    decisions: [],
    addDecision: {},
    deleteDecision: {}
}
const mutations = {
    FETCH_DECISIONS(state, decisions) {
        state.decisions = decisions
    },
    ADD_DECISION(state, payload) {
        state.addDecision = payload
    },
    DELETE_DECISION(state, payload) {
        state.deleteDecision = payload
    }
}
export const decision = {
    namespaced: true,
    state,
    actions,
    mutations
};