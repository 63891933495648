var protocol = window.location.protocol;
var currentHost = "";
var ckfinder = "/ckfinder/"

if(process.env.NODE_ENV == "development") {
    currentHost = process.env.VUE_APP_HOST_DEVELOPMENT
} else {
    currentHost = `${protocol}//${window.location.hostname}`
}
export const httpUpload = currentHost + ckfinder;

