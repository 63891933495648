<template lang="vi">
<div>
        <b-col class="pl-0" v-if="(entry.phuTrach === rolePhuTrach || roleLevel == 3 && entry.archived == false)">
            <b-card style="height:400px">
                <h5 style="font-weight: bold">Tiếp nhận phản ánh</h5>
                <div class="mt-5">
                    <label>Ngày bắt đầu <span class="red">*</span></label>
                    <date-picker v-model="form.thoiGianBatDau" value-type="timestamp" placeholder="Chọn ngày bắt đầu" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY"  :disabled-date="notBeforeToday"></date-picker>
                </div>
                <div class="mt-4">
                    <label>Hạn xử lý <span class="red">*</span></label>
                    <date-picker v-model="form.thoiGianKetThuc" value-type="timestamp" :disabled-date="notBeforeToday" placeholder="Chọn hạn xử lý" :first-day-of-week="1" lang="vi" format="DD-MM-YYYY"></date-picker>
                </div>
                <b-form-group class="mt-4">
                    <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                        {{addErrorMessage}}
                    </div>
                    <b-button variant="success" class="mr-2" @click.prevent="updateDate">
                        Lưu lại
                    </b-button>
                    <router-link :to="{name: 'admin.sentiments.edit', params: {id:entry.id , form: entry}}">
                        <b-button variant="danger">
                            Hủy bỏ
                        </b-button>
                    </router-link>
                </b-form-group>
            </b-card>
        </b-col>
    <LightBox ref="lightbox" :showCaption="false" :showLightBox="false" :media="getListMedia" />
</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/vi';
import {
    httpClient
} from "../../../../_utils/httpClient";
import {
    httpPreview
} from "../../../../_utils/httpPreview";
import _ from "lodash";
import LightBox from 'vue-it-bigger'
import {
    Base64
} from 'js-base64'
import { checkFile } from './checkTypeFile';
export default {
    name: 'create',
    components: {
        LightBox,
        DatePicker
    },
    computed: {
        allowAccess() {
            let userData = localStorage.getItem('token').split('.')[1]
            let userInfo = JSON.parse(Base64.decode(userData))
            return userInfo.role_level > 3 && (this.form.trangThai === `Đã xử lý` || this.form.trangThai === `Không chính xác`)
        },
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Phản ánh',
                to: '/admin/sentiments',
            }, {
                text: 'Chi tiết',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            entry: {
                id: '',
                theLoai: '',
                noiDung: '',
                diaDiem: '',
                thoiGian: '',
                phuTrach: null,
                trangThai: '',
                media: [],
                logs: []
            },
            form: {
                thoiGianBatDau: new Date().setHours(0, 0, 0, 0),
                thoiGianKetThuc: new Date().setHours(0, 0, 0, 0),
            },
            loading: false,
            getListMedia: [],
            rolePhuTrach: "",
            roleLevel: ""
        };
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        notBeforeToday(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        validator() {
            if (this.form.thoiGianBatDau === null) {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Ngày bắt đầu không được trống.`
                return false
            }
            if (this.form.thoiGianKetThuc === null) {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Hạn xử lý không được trống.`
                return false
            }
            return true
        },
        async updateDate() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            let payload = {
                ...this.form
            }
            let response = await httpClient.post(`sentiment/receive`, payload)
            if (response.data.code === 0) {
                await this.$router.push({
                    name: 'admin.sentiments.updateStatus',
                    params: {
                        id: this.entry.id,
                        form: this.entry
                    }
                });
            } else {
                this.showAddErrorMessage = true
                this.addErrorMessage = response.data
            }
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            return response.data.data
        },
    },
    async created() {
        let userData = localStorage.getItem('token').split('.')[1];
        let userInfo = JSON.parse(Base64.decode(userData));
        this.rolePhuTrach = userInfo.phone;
        this.roleLevel = userInfo.role_level;
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id)
        }
        const setMedia = new Set()
        this.entry = {
            ...cloneItem
        }
        this.form.id = cloneItem.id;
        if (this.entry.logs === null) {
            this.entry.logs = []
        }
        if (cloneItem.media === undefined || cloneItem.media == null) {
            cloneItem.media = []
        } else {
            cloneItem.media.forEach(e => {
                setMedia.add(e)
            })
        }
        let media = []
        setMedia.forEach(item => {
            if (item === undefined) return
            item = item.startsWith(`http`) ? item : httpPreview + item
            if (this.checkTypeFile(item) == 'image') {
                media.push({
                    type: 'image',
                    src: item,
                    thumb: item
                })
            }
            if (this.checkTypeFile(item) == 'video') {
                media.push({
                    type: 'video',
                    sources: [{
                        src: item,
                        type: 'video/mp4'
                    }],
                    width: 800, // Required
                    height: 600, // Required
                    autoplay: true
                })
            }
        })
        this.getListMedia = media
    },
};
</script>
