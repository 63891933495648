import {docsService} from "../_services/docs.services";

const actions = {
    fetchDocs({commit}) {
        return docsService.fetchDocs()
            .then((response) => {
                console.log(`FETCH_DOCS`, response)
                commit("FETCH_DOCS", response);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    addDoc({commit}, payload) {
        return docsService.addDoc(payload)
            .then((response) => {
                console.log(`ADD_DOC`, response)
                commit("ADD_DOC", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
    deleteDoc({commit}, payload) {
        return docsService.deleteDoc(payload)
            .then((response) => {
                console.log(`DELETE_DOC`, response)
                commit("DELETE_DOC", payload);
                return response
            })
            .catch((error) => {
                console.log(error);
            });
    },
}
const state = {
    docs: [],
    addDoc: {},
    deleteDoc: {}
}
const mutations = {
    FETCH_DOCS(state, docs) {
        state.docs = docs
    },
    ADD_DOC(state, payload) {
        state.addDoc = payload
    },
    DELETE_DOC(state, payload) {
        state.deleteDoc = payload
    }
}
export const doc = {
    namespaced: true,
    state,
    actions,
    mutations
};