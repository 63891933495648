import {accountService} from "../_services/account.services";

const state = {status: {message: ''}, user: null};
const getters = {
    getUser: state => state.user
}
const actions = {
    login({dispatch, commit}, {username, password}) {
        commit('loginRequest', {username});

        accountService.login(username, password)
            .then(
                data => {
                    commit('loginSuccess', {data, user: username})
                    console.log(`accountService`, data)
                }
            )
            .catch(error => {
                commit('loginFailure', error);
                dispatch('alert/error', error, {root: true});
            });
    },
    register({commit}, user) {
        return accountService.register(user)
            .then(
                user => {
                    console.log(`moduleuser`, user)
                    commit('registerSuccess', user);
                }
            ).catch(e => {
                return e
            });
    },
    recovery({commit}, user) {
        return accountService.recovery(user)
            .then(
                user => {
                    console.log(`moduleuser`, user)
                    commit('recoverySuccess', user);
                }
            ).catch(e => {
                return e
            });
    }
};

const mutations = {
    loginRequest(state, user) {
        state.status = {loggingIn: true};
        state.user = user;
    },
    loginSuccess(state, data) {
        state.status = {loggedIn: true, message: data.data};
        state.user = data.user;
    },
    loginFailure(state, data){
        state.status = {loggedIn: false, message: data};
    },
    registerSuccess(state) {
        state.status = {};
    },
    recoverySuccess(state) {
        state.status = {};
    },

};

export const account = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};