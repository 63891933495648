<template>
    <div>
        <div v-if="isShow" class="card">
            <div class="card-body">
                <div class="mb-2 row">
                    <div class="text-center col-12">
                        <img style="height: 200px; padding-left: 20px;" src="../../../../assets/images/no-data.jpg" alt="" />
                    </div>
                    <i class="text-center col-12" style="font-size: 18px;">Không tìm thấy dữ liệu</i>
                </div>
            </div>
        </div>
        <div v-else>
            <b-card>
                <b-form-group>
                    <label>Trạng thái hiện tại</label>
                    <b-input v-model="entry.trangThai" disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Danh mục</label>
                    <b-input v-model="entry.theLoai" disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Phụ trách</label>
                    <b-input v-model="entry.tenPhuTrach" disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Người gửi</label>
                    <b-input v-model="entry.tenNguoiGui" disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Địa điểm</label>
                    <b-input v-model="entry.diaDiem" disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Thời gian</label>
                    <b-input :value="entry.thoiGian | formatDateTime" disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Nội dung phản ánh</label>
                    <b-textarea :value="entry.noiDung" disabled rows="3" max-rows="10"></b-textarea>
                </b-form-group>
                <b-form-group>
                    <label>Thời gian bắt đầu</label>
                    <b-input :value="entry.thoiGianBatDau / 1000 | formatDate " disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <label>Hạn xử lý</label>
                    <b-input :value="entry.thoiGianKetThuc / 1000 | formatDate " disabled></b-input>
                </b-form-group>
                <b-form-group>
                    <div v-for="(image, index) in getListMedia" :key="index"
                        style="display: inline-block; margin: 5px 5px">
                        <div v-if="!image.sources">
                            <img class="img-cover" :src="parseThumb(image)"
                                style="height: 200px; width:200px; cursor: pointer;" @click="openGallery(image)" />
                        </div>
                        <div v-else>
                            <video controls width="200px" height="200px">
                                <source :src="parseThumb(image)">
                            </video>
                        </div>
                    </div>
                </b-form-group>
            </b-card>
            <div class="card" v-show="this.roleLevel !== 1 && this.roleLevel !== 2">
                <div class="card-body">
                    <b-form-group>
                        <label>Lịch sử xử lý</label>
                    </b-form-group>
                    <ul class="verti-timeline list-unstyled" v-for="item in entry.logs.slice(0).reverse()"
                        :key="item.Time">
                        <li class="event-list">
                            <div class="event-timeline-dot"><i class="bx bx-right-arrow-circle font-size-18"></i>
                            </div>
                            <div class="media">
                                <div class="me-3">
                                    <h5 class="font-size-16">{{item.Time}}<i
                                            class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                    </h5>
                                </div>
                                <div class="media-body" style="max-width: 450px">
                                    <pre class="font-size-14"
                                        style="font-family: Quicksand, sans-serif;">{{item.Content}}</pre>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <LightBox ref="lightbox" :showCaption="false" :showLightBox="false" :media="getListMedia" />
        </div>
    </div>
</template>

<script>
import {
    httpClient
} from "../../../../_utils/httpClient";
import {
    httpPreview
} from "../../../../_utils/httpPreview";
import LightBox from 'vue-it-bigger'
import { checkFile } from "./checkTypeFile";
import { Base64 } from "js-base64";
export default {
    name: 'create',
    components: {
        LightBox
    },
    data: function () {
        return {
            entry: {
                id: "",
                theLoai: '',
                noiDung: '',
                diaDiem: '',
                thoiGian: '',
                phuTrach: null,
                tenPhuTrach: "",
                trangThai: '',
                media: [],
                logs: [],
                thoiGianBatDau: null,
                thoiGianKetthuc: null
            },
            form: {
                id: "",
            },
            loading: false,
            underUsers: [],
            sentimentStatus: [],
            getListMedia: [],
            role: '',
            roleAdmin: '',
            roleLevel: '',
            isShow: false,
        };
    },
    watch: {
        "$route.params.id": {
            handler: function () {
                this.getData()
            }
        },
        "$route.name": {
            handler() {
                this.getData()
            }
        }
    },
    methods: {
        checkTypeFile(src) {
            return checkFile(src);
        },
        openGallery(image) {
            console.log('this.getListMedia', this.getListMedia)
            let index = this.getListMedia.findIndex(k => {
                if (k.src && image.src) {
                    let find = image.src.startsWith(`http`) ? image.src : httpPreview + image.src
                    if (k.src === find) return true
                }
                if (k.sources && k.sources[0].src && image && image.sources && image.sources[0].src) {
                    let find = image.sources[0].src.startsWith(`http`) ? image.sources[0].src : httpPreview + image.sources[0].src
                    if (k.sources[0].src === find) return true
                }
            })
            console.log(`index`, index)
            this.$refs.lightbox.showImage(index)
        },
        parseThumb(image) {
            if (image && image.sources) return image.sources[0].src
            // if (image && image.sources && image.sources[0].src.endsWith(`.mov`)) return `https://png.pngtree.com/png-vector/20190411/ourmid/pngtree-vector-play-icon-png-image_924817.jpg`
            if (!image.src.startsWith(`http`)) {
                return httpPreview + image.src
            }
            return image.src
        },
        async getById(id) {
            let response = await httpClient.get(`sentiment`, {
                params: {
                    id
                }
            })
            if(response.toString().includes("code 404")) {
                this.isShow = true
                this.$emit('show', true)
                return false
            }
            this.$emit('show', false)
            this.isShow = false
            return response.data.data
        },
        async getData() {
            let cloneItem = await this.getById(this.$route.params.id)
            if(cloneItem) {
                const setMedia = new Set()
                this.entry = {
                    ...cloneItem
                }
                if (this.entry.logs === null) {
                    this.entry.logs = []
                }
                this.form.id = cloneItem.id
                if (cloneItem.media === undefined || cloneItem.media == null) {
                    cloneItem.media = []
                } else {
                    cloneItem.media.forEach(e => {
                        setMedia.add(e)
                    })
                }
                let media = []
                setMedia.forEach(item => {
                    if (item === undefined) return
                    item = item.startsWith(`http`) ? item : httpPreview + item
                    if (checkFile(item) == 'image') {
                        media.push({
                            type: 'image',
                            src: item,
                            thumb: item
                        })
                    }
                    if (checkFile(item) == 'video') {
                        media.push({
                            type: 'video',
                            sources: [{
                                src: item,
                                type: 'video/mp4'
                            }],
                            width: 800,
                            height: 600,
                            autoplay: false
                        })
                    }
                })
                this.getListMedia = media
            }
        },
        getUserData() {
            let userData = localStorage.getItem('token').split('.')[1];
            let userInfo = JSON.parse(Base64.decode(userData));
            this.role = userInfo.phone;
            this.roleAdmin = userInfo.role;
            this.roleLevel = userInfo.role_level;
        }
    },
    async created() {
        this.getUserData()
        this.getData();
    },
};
</script>

<style scoped>
/* .show-img {
    position: relative;
}
.show-img::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: -40px;
    right: -40px;
    font-size: 30px;
    background-color: #000;
    z-index: 99999;
} */
.img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

img {
    vertical-align: unset;
}

pre {
    white-space: pre-wrap;
}

.text-sm {
    font-weight: 600;
}

.vib-open {
    overflow: hidden;
    pointer-events: none;
}

.no-scroll {
    overflow-y: hidden;
}

.vib-hideable {
    transition: opacity .5s ease;
}

.vib-hidden {
    opacity: 0;
}

/* elements styling */

.vib-container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2000;
    -webkit-align-items: center;
    -moz-box-sizing: border-box;
    -webkit-justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
}

.vib-content {
    position: relative;
    margin: 0;
    display: block;
}

.vib-image {
    cursor: pointer;
    max-height: calc(100vh);
    cursor: pointer;
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.vib-thumbnail-wrapper {
    background: rgba(0, 0, 0, 0.2);
    padding: 10px 12px;
    text-align: center;
    white-space: nowrap;
    position: fixed;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    top: 0px;
}

.vib-thumbnail {
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .2);
    cursor: pointer;
    display: inline-block;
    height: 50px;
    margin: 2px 2px 0 2px;
    overflow: hidden;
    width: 50px;
}

.vib-thumbnail-active {
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px white;
    cursor: pointer;
    display: inline-block;
    height: 50px;
    margin: 2px 2px 0 2px;
    overflow: hidden;
    width: 50px;
}

.vib-footer {
    box-sizing: border-box;
    color: white;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    line-height: 1.3;
    padding: 10px;
    justify-content: space-between;
    text-align: left;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.vib-footer-count {
    flex-shrink: 0;
    padding-left: 1em;
}

.vib-close {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    position: fixed;
    top: 0;
    right: 0;
    vertical-align: bottom;
    height: 65px;
    width: 60px;
    padding: 15px 12px 15px 20px;
    border-bottom-left-radius: 50%;
}

.vib-arrow {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    position: absolute;
    top: 50%;
    user-select: none;
    height: 65px;
    margin-top: -60px;
    width: 60px;
}

.vib-arrow-left {
    left: 0;
    padding-right: 20px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.vib-arrow-right {
    right: 0;
    padding-left: 20px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}

@media (min-width: 500px) {
    .vib-thumbnail-arrow {
        width: 40px;
    }
}

@media (min-width: 768px) {
    .vib-arrow {
        width: 60px;
    }
}

/* image transitions */

.vib-image-transition-enter-active,
.vib-image-transition-leave-active {
    transition: opacity .2s ease;
}

.vib-image-transition-enter,
.vib-image-transition-leave-to {
    opacity: 0;
}

.vib-image-no-transition-enter-active,
.vib-image-no-transition-leave-active {
    transition: none;
}

.vib-image-no-transition-enter,
.vib-image-no-transition-leave-to {
    opacity: 0;
}

/* container transitions */

.vib-container-transition-enter-active,
.vib-container-transition-leave-active {
    transition: opacity .3s ease;
}

.vib-container-transition-enter,
.vib-container-transition-leave-to {
    opacity: 0;
}

.video-background {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    width: calc(90vw);
    height: calc(90vh);
}

.video-background iframe {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50.25vw;
    max-height: 100vh;
    max-width: 182.78vh;
}
.action-column {
    width: 120px;
    text-align: center;
}
.red {
    color: red;
}
</style>
