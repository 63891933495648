import { httpClient } from "../_utils/httpClient";
import { router } from "../router";

export const accountService = {
  login,
  register,
  logout,
  recovery
};
const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
};

function login(username, password) {
  return httpClient
    .post(`/auth/login`, { id: username, password })
    .then((result) => {
      if (!result.data) {
        return Promise.reject(result.response.data.message);
      }
      if (result.data.code === 0) {
        asyncLocalStorage.setItem("token", result.data.token).then(() =>
          asyncLocalStorage.setItem("user", username).then(() => {
            router.push({ name: "admin.home" });
            return Promise.resolve(result.data.message);
          })
        );
      } else {
        return Promise.reject(result.data.message);
      }
    })
    .catch((error) => {
      console.log(`e`, error);
      var result = document.getElementById("alerst");
      if (result) result.classList.add("alert");
      return Promise.reject(`Sai thông tin đăng nhập`);
    });
}

function logout() {
  localStorage.clear();
}

function register(user) {
  return httpClient
    .post(`/auth/register`, user)
    .then((result) => {
      if (result.data.code === 0) {
        asyncLocalStorage.setItem("token", result.data.token).then(() =>
          asyncLocalStorage.setItem("user", user.id).then(() => {
            router.push({ name: "admin.home" });
            return result.data.message;
          })
        );
        return result.data.message;
      } else {
        return Promise.reject(result.data.message);
      }
    })
    .catch((error) => {
      console.log(`e`, error);
      return Promise.reject(error);
    });
}

function recovery(user) {
  return httpClient
    .post(`/auth/forgot`, user)
    .then((result) => {
      if (result.data.code === 0) {
        router.push({ name: "Login" });
      } else {
        return Promise.reject(result.data.message);
      }
    })
    .catch((error) => {
      console.log(`e`, error);
      return Promise.reject(error);
    });
}
